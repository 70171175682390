function recaptchaCallback() {
    $('#f_recaptcha').valid();
}

$(document).ready(function(){
    
    /* VIDEO BG */

    var timeoutId;
    // var $videoBgAspect = $(".videobg-aspect");
    // var $videoBgWidth = $(".videobg-width");
    // var videoAspect = $videoBgAspect.outerHeight() / $videoBgAspect.outerWidth();

    // function videobgEnlarge() {
    //   windowAspect = ($(window).height() / $(window).width());
    //   if (windowAspect > videoAspect) {
    //     $videoBgWidth.width((windowAspect / videoAspect) * 100 + '%');
    //   } else {
    //     $videoBgWidth.width(100 + "%")
    //   }
    // }

    // $(window).resize(function() {
    //   clearTimeout(timeoutId);
    //   timeoutId = setTimeout(videobgEnlarge, 100);
    // });

    // $(function() {
    //   videobgEnlarge();
    // });

    function videoHeight() {
        var height = $('.videobg-make-height').outerHeight(true);
        console.log(height);

        $('.videobg').css('height', height);
        $('section.header').css('height', height);
    }

    $(window).resize(function() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(videoHeight, 100);
    });
    $(function() {
      videoHeight();
    });


    // Smooth Anchors

    $(function(){
        $("a[href*='#']:not([href='#'])").click(function(){
            if (
                location.hostname == this.hostname
                && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"")
            ) {
                var anchor = $(this.hash);
                anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");
                if ( anchor.length ) {
                    $("html, body").animate( { scrollTop: anchor.offset().top }, 750);
                }
            }
        });
    });

    // Owl carousel 1

    var owl = $('.owl-carousel');



    owl.owlCarousel({
        nav: false,
        loop: false,
        items: 1, 
        dots:true
    });


    
    // Restart timer autoplay when translated

    $('#owlCarousel').on('translated.owl.carousel', function(e){
        owl.trigger('stop.owl.autoplay');
        owl.trigger('play.owl.autoplay');
    });

    // Add the correct height to each picture

    function slideHeight(){
      var slideTextHeight;
      $('.slide').each(function(){
        $(this).find('.textSlide').children().each(function(){
           var slideTextHeight = $(this).outerHeight(true) + 200;
        })
        $(this).css('height', slideTextHeight);
      });
    }

    // Textarea auto adjust when typing

    $(function(){

        //  changes mouse cursor when highlighting loawer right of box
        $(document).on('mousemove', 'textarea', function(e){
            var a = $(this).offset().top + $(this).outerHeight() - 16,  //  top border of bottom-right-corner-box area
                b = $(this).offset().left + $(this).outerWidth() - 16;  //  left border of bottom-right-corner-box area
            $(this).css({
                cursor: e.pageY > a && e.pageX > b ? 'nw-resize' : ''
            });
        })

        //  the following simple make the textbox "Auto-Expand" as it is typed in
        .on('keyup', 'textarea', function(e){
            //  the following will help the text expand as typing takes place
            while($(this).outerHeight() < this.scrollHeight + parseFloat($(this).css("borderTopWidth")) + parseFloat($(this).css("borderBottomWidth"))){
                $(this).height($(this).height()+1);
            };
        });
    });


    $('.menuButton').click(function(){
      $('.menu').addClass('active');
      $('.backgroundBlack').addClass('active');
    })

    $('.cross').click(function(){
      $('.menu').removeClass('active');
      $('.backgroundBlack').removeClass('active');
    })

    $('.backgroundBlack').click(function(){
      $('.menu').removeClass('active');
      $(this).removeClass('active');
    })


    slideHeight();
    $( window ).resize(slideHeight);

    $.validator.addMethod("validateRecaptcha", function (value, element) {
          if (grecaptcha.getResponse() == '') {
              return false;
          } else {
              return true;
          }
      }, "Vous devez valider le reCAPTCHA");

    setTimeout(function () {
      if ($('#contact_form').length) {
          $('#contact_rgpd').rules('add', {
                validateCgv: true
            });
          $('#f_recaptcha').rules('add', {
              validateRecaptcha: true
          });
        }
    }, 100);        



    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide."
    });
    $.validator.addMethod("validateCgv", function (value, element) {
            return $('#contact_rgpd').is(':checked');
        }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $('#contact_form').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.checkboxAlert');
                error.addClass("checkbox");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });
});



